import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", [_c("select-filter", { key: "MethodId", attrs: { "label": "Sterilisation Method", "mode": "multiple", "placeholder": "Select Sterilisation Method", "reference": "sterilization.common.methods", "source": "id", "source-label": "name" } }), _c("operator-filter", { key: "Code", attrs: { "label": "Method Code", "type": "string" } }), _c("select-filter", { key: "StackingPatternId", attrs: { "label": "Stacking Pattern", "mode": "multiple", "placeholder": "Select Stacking Pattern", "reference": "sterilization.common.stacking-patterns", "source": "id", "source-label": "description" } }), _c("operator-filter", { key: "IssueDate", attrs: { "label": "Issue Date", "type": "date" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterDataEntry"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterDataEntry = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "filter-data-entries" }, [_c("resource", { attrs: { "name": "sterilization.common.stacking-patterns", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.methods", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.data-entries", "api-url": _vm.apiUrl, "filter": _vm.FilterDataEntry, "redirect-route": _vm.redirectRoute } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "FilterDataEntry",
  props: {
    redirectRoute: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      FilterDataEntry,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
